import { MUCSLAASB119 } from '../JavaScript/mucslaasb-119.js';

if (module.hot) {
  module.hot.accept();
}

document.addEventListener(
  'DOMContentLoaded',
  () => {
    //suppress rspBackdrop from humhub js
    //todo: remove that after we´ve access to overwrite humhub js

    const closeMenuBtn = document.getElementsByClassName('menu-toggle');
    for (let i = 0; i < closeMenuBtn.length; i++) {
      closeMenuBtn[i].addEventListener('click', function () {
        let rspBackdrop = document.getElementById('rsp-backdrop');
        if (rspBackdrop instanceof HTMLElement) {
          rspBackdrop.remove();
        }
      });
    }

  });

// MUCSLAASB-104/MUCSLAASB-163:
// The default Enterprise Theme uses jquery.niceScroll for scrolling in the sidebar.
// This does not work reliably for all users.
// We cannot hook the behaviour, so the setTimeout is currently the best solution here.
// If you know a better way, feel free to update this.
document.addEventListener(
    'DOMContentLoaded',
    () => {
        setTimeout(
            function () {
                // there are some inline-styles set (I guess from jquery.niceScroll) we need to overwrite here.
                let sidebarWrapper = <HTMLElement>document.querySelector('#sidebar-wrapper');
                if (sidebarWrapper) {
                    sidebarWrapper.style.setProperty('overflow', 'visible', 'important');
                    sidebarWrapper.style.setProperty('padding-bottom', '40px');
                }
            },
            1000,
        );
    });
/**
 * Update JavaScript code on the HumHub registration page.
 * We got this JS code from the customer, since it does not fit our TypeScript standards,
 * I've moved into function in JS-Land from where we can import / call it.
 */
document.addEventListener('DOMContentLoaded', function () {
    // Only execute this code on /user/registration
   if (window.location.pathname !== '/user/registration') {
       return;
   }

   MUCSLAASB119();
});
