/**
 * Update some code on the HumHub registration page.
 * We got this code from the customer, that why it's located in a JavaScript file and imported from TypeScript.
 *
 * @See: MUCSLAASB-119
 */
export function MUCSLAASB119() {
    $(document).ready(function(){

        $("#registration-form .form-group.field-user-contact_person label").append("&nbsp;");
        $("#registration-form .contact_person_tooltip").detach().appendTo("#registration-form .form-group.field-user-contact_person label").show();
        $("#registration-form #user-contact_person").attr("placeholder", "Rita Musterfrau, ASB Beispielstadt, name@asb-bsp.de");

        if ($("#groupuser-group_id").prop("tagName").toLowerCase() === "select") {
            $("#registration-form .form-group.field-user-working_type").addClass("required");
            $("#registration-form .form-group.field-user-contact_person").addClass("required");
            let error_in_form = false;
            $(".help-block-error").each(function(){
                if($(this).text()!="")  error_in_form = true;
            });
            if(error_in_form)
            {
                $("#groupuser-group_id").prepend("<option value='-1'>Bitte wähle deine Gliederung oder gGmbH</option>");
            }
            else
            {
                $("#groupuser-group_id").prepend("<option value='-1' selected='selected'>Bitte wähle deine Gliederung oder gGmbH</option>");
                $("#groupuser-group_id").selectedIndex = 0;
                $("button[name='save']").prop( "disabled", true );
                $("button[name='save']").html( "Bitte wähle deine<br/>Gliederung oder gGmbH");
            }
            $("#groupuser-group_id").select2();
        }
    });
    $("#groupuser-group_id").change(function (){
        if ($("#groupuser-group_id").prop("tagName").toLowerCase() === "select") {
            if($("#groupuser-group_id").val()<0)
            {
                $("button[name='save']").prop( "disabled", true);
                $("button[name='save']").html( "Bitte wähle deine<br/>Gliederung oder gGmbH");
            }
            else if($("#groupuser-group_id").val()>0)
            {
                $("button[name='save']").prop( "disabled", false);
                $("button[name='save']").html( "Benutzerkonto erstellen");
            }
        }
    });
}
